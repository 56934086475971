import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Section from '../components/Section'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Not found" />
    <Section className="vh-100" fluid>
      <Row className="text-center h-100 align-items-center">
        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <h2 className="font-bitter pb-4">
            Oops the page can not be found.
          </h2>
          <Button href="/" className="mt-3">
            Go to homepage
          </Button>
        </Col>
      </Row>
    </Section>
  </Layout>
)

export default NotFoundPage
